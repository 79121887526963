<div class="relative flex flex-col w-full h-full" *transloco="let t">

    <!-- Dismiss button -->
    <div class="absolute top-0 right-0 pt-4 pr-4">
        <button mat-icon-button [matDialogClose]="undefined" [disabled]="loading">
            <mat-icon class="text-secondary" [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>

    <!-- Content -->
    <div class="flex flex-col sm:flex-row flex-auto items-center sm:items-start pt-8 px-4 sm:px-8 pb-6 sm:pb-8">

        <!-- Success Icon -->
        <div
            class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500">
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:check'"></mat-icon>
        </div>

        <!-- Info section -->
        <div class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 space-y-1 text-center sm:text-left">

            <!-- Title -->
            <div class="text-xl leading-6 font-medium">{{t('Verification code')}}</div>

            <!-- Message -->
            <div class="text-md leading-6 font-light">{{t('The verification code was sent to')}}</div>

            <!-- Phone number -->
            <div class="text-md leading-6 font-normal">{{phoneNumber}}</div>

            <fuse-alert *ngIf="error" [type]="'error'" [dismissible]="true">
                <mat-icon class="icon-size-5" fuseAlertIcon [svgIcon]="'mat_outline:warning'"></mat-icon>
                <span fuseAlertTitle>{{t('An issue has occurred')}}</span>
                {{t(error)}}
            </fuse-alert>

            <!-- Code input -->
            <div class="flex flex-col items-center pt-6">
                <code-input
                    [codeLength]="6"
                    (codeChanged)="onCodeChanged($event)"
                    (codeCompleted)="onCodeCompleted($event)"
                    [disabled]="loading"
                    [initialFocusField]="0"
                    [isFocusingOnLastByClickIfFilled]="true"
                    #codeInput
                > </code-input>
            </div>

            <!-- Resend message -->
            <div class="flex flex-col sm:flex-row flex-auto items-center sm:items-start pt-4">
                <div class="text-md leading-6 font-light h-10 grid content-center">{{t('Didn\'t receive the SMS?')}}</div>
                <button mat-button color="primary" [disabled]="loading" (click)="reSendCode()">{{t('Resend')}}</button>
            </div>

        </div>

    </div>

    <div class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">

        <!-- Cancel -->
        <button mat-stroked-button [matDialogClose]="undefined" [disabled]="loading">
            {{t('Cancel')}}
        </button>

        <!-- Confirm -->
        <button mat-flat-button [color]="'primary'" (click)="sendCode(code)" [disabled]="loading">
            <div class="flex flex-row flex-auto items-start justify-center">
                <span class="h-10 grid content-center mr-2">{{t('Confirm')}}</span>
                <span class="h-10 grid content-center" *ngIf="loading">
                    <mat-progress-spinner
                        [diameter]="24"
                        [mode]="'indeterminate'">
                    </mat-progress-spinner>
                </span>
            </div>
        </button>

    </div>

</div>
