import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, tap } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';
import { UserService } from '../user/user.service';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    excludeClient: string[] = ['checkout'];

    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService
    ) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation> {
        return this._httpClient.get<Navigation>('api/common/navigation').pipe(
            tap((navigation) => {

                this._navigation.next(navigation);
                // Validate menu permissions by role
                // if (this._userService.isMerchant()) {

                //     this._navigation.next(navigation);
                // } else {

                //     const newNav = {
                //         compact: navigation.compact.filter(item => !this.excludeClient.includes(item.id)),
                //         default: navigation.default.filter(item => !this.excludeClient.includes(item.id)),
                //         futuristic: navigation.futuristic.filter(item => !this.excludeClient.includes(item.id)),
                //         horizontal: navigation.horizontal.filter(item => !this.excludeClient.includes(item.id))
                //     };

                //     this._navigation.next(newNav);
                // }
            })
        );
    }
}
