/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    // {
    //     id   : 'dashboard',
    //     title: 'Dashboard',
    //     type : 'basic',
    //     icon : 'mat_outline:dashboard',
    //     link : '/dashboard'
    // },
    {
        id   : 'clients',
        title: 'Clients',
        type : 'basic',
        icon : 'mat_outline:supervised_user_circle',
        link : '/clients'
    },
    {
        id   : 'contests',
        title: 'Contests',
        // type : 'collapsable',
        type : 'basic',
        icon : 'mat_outline:stars',
        link : '/contests'
        // children: [
        //     {
        //         id   : 'contests.raffle',
        //         title: 'Raffle',
        //         type : 'basic',
        //         icon : 'mat_outline:casino',
        //         link : '/contests/raffle'
        //     },
        //     {
        //         id   : 'contests.lottery',
        //         title: 'Lottery',
        //         type : 'basic',
        //         icon : 'mat_outline:confirmation_number',
        //         link : '/contests/lottery'
        //     }
        // ]
    },
    {
        id   : 'finances',
        title: 'Finance',
        type : 'collapsable',
        icon : 'mat_outline:monetization_on',
        children: [
            {
                id   : 'finances.deposits',
                title: 'Deposits',
                type : 'basic',
                icon : 'mat_outline:arrow_circle_down',
                link : '/finances/deposits'
            },
            {
                id   : 'finances.withdrawals',
                title: 'Withdrawals',
                type : 'basic',
                icon : 'mat_outline:arrow_circle_up',
                link : '/finances/withdrawals'
            },
            {
                id   : 'finances.purchases',
                title: 'Purchases',
                type : 'basic',
                icon : 'mat_outline:shopping_cart',
                link : '/finances/purchases'
            },
            {
                id   : 'finances.rewards',
                title: 'Rewards',
                type : 'basic',
                icon : 'mat_outline:card_giftcard',
                link : '/finances/rewards'
            },
            {
                id   : 'finances.refunds',
                title: 'Refunds',
                type : 'basic',
                icon : 'mat_outline:rotate_left',
                link : '/finances/refunds'
            },
        ]
    },
    {
        id   : 'users',
        title: 'Users',
        type : 'basic',
        icon : 'mat_outline:manage_accounts',
        link : '/users'
    },
    {
        id   : 'settings',
        title: 'Settings',
        type : 'collapsable',
        icon : 'mat_outline:settings',
        children: [
            {
                id   : 'settings.categories',
                title: 'Categories',
                type : 'basic',
                icon : 'mat_outline:category',
                link : '/settings/categories'
            },
            // {
            //     id   : 'settings.set_up',
            //     title: 'Set Up',
            //     type : 'basic',
            //     icon : 'mat_outline:settings_suggest',
            //     link : '/settings/config'
            // }
        ]
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    // {
    //     id   : 'dashboard',
    //     title: 'Dashboard',
    //     type : 'basic',
    //     icon : 'mat_outline:dashboard',
    //     link : '/dashboard'
    // },
    {
        id   : 'clients',
        title: 'Clients',
        type : 'basic',
        icon : 'mat_outline:supervised_user_circle',
        link : '/clients'
    },
    {
        id   : 'contests',
        title: 'Contests',
        // type : 'aside',
        type : 'basic',
        icon : 'mat_outline:stars',
        link : '/contests'
        // children: []
    },
    {
        id   : 'finances',
        title: 'Finance',
        type : 'aside',
        icon : 'mat_outline:monetization_on',
        children: []
    },
    {
        id   : 'users',
        title: 'Users',
        type : 'basic',
        icon : 'mat_outline:manage_accounts',
        link : '/users'
    },
    {
        id   : 'settings',
        title: 'Settings',
        type : 'aside',
        icon : 'mat_outline:settings',
        children: []
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    // {
    //     id   : 'dashboard',
    //     title: 'Dashboard',
    //     type : 'basic',
    //     icon : 'mat_outline:dashboard',
    //     link : '/dashboard'
    // },
    {
        id   : 'clients',
        title: 'Clients',
        type : 'basic',
        icon : 'mat_outline:supervised_user_circle',
        link : '/clients'
    },
    {
        id   : 'contests',
        title: 'Contests',
        // type : 'aside',
        type : 'basic',
        icon : 'mat_outline:stars',
        link : '/contests'
        // children: []
    },
    {
        id   : 'finances',
        title: 'Finance',
        type : 'aside',
        icon : 'mat_outline:monetization_on',
        children: []
    },
    {
        id   : 'users',
        title: 'Users',
        type : 'basic',
        icon : 'mat_outline:manage_accounts',
        link : '/users'
    },
    {
        id   : 'settings',
        title: 'Settings',
        type : 'aside',
        icon : 'mat_outline:settings',
        children: []
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    // {
    //     id   : 'dashboard',
    //     title: 'Dashboard',
    //     type : 'basic',
    //     icon : 'mat_outline:dashboard',
    //     link : '/dashboard'
    // },
    {
        id   : 'clients',
        title: 'Clients',
        type : 'basic',
        icon : 'mat_outline:supervised_user_circle',
        link : '/clients'
    },
    {
        id   : 'contests',
        title: 'Contests',
        // type : 'group',
        type : 'basic',
        icon : 'mat_outline:stars',
        link : '/contests'
        // children: []
    },
    {
        id   : 'finances',
        title: 'Finance',
        type : 'group',
        icon : 'mat_outline:monetization_on',
        children: []
    },
    {
        id   : 'users',
        title: 'Users',
        type : 'basic',
        icon : 'mat_outline:manage_accounts',
        link : '/users'
    },
    {
        id   : 'settings',
        title: 'Settings',
        type : 'group',
        icon : 'mat_outline:settings',
        children: []
    }
];
