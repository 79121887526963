import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { CodeInputComponent } from 'angular-code-input';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
    selector: 'second-factor-code',
    templateUrl: './second-factor-code.component.html',
    styleUrls: ['./second-factor-code.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SecondFactorCodeComponent implements OnInit, OnDestroy {

    @ViewChild('codeInput') codeInput !: CodeInputComponent;

    loading: boolean = false;

    error: string;

    code: string = '';

    token: string;

    captchaToken: string;

    email: string;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { token: string; captchaToken: string; email: string },
        private _authService: AuthService,
        public matDialogRef: MatDialogRef<SecondFactorCodeComponent>
    ) {
        this.token = data.token;
        this.email = data.email;
        this.captchaToken = data.captchaToken;
    }

    /**
     * On init
     */
    ngOnInit(): void {
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    // this called every time when user changed the code
    onCodeChanged(code: string): void {
        this.code = code;
    }

    // this called only if user entered full code
    onCodeCompleted(code: string): void {
        this.sendCode(code);
    }

    /**
     * Send code to api
     *
     * @param code
     */
    sendCode(code: string): void {

        if (code.length < 6) {
            return;
        }

        if (!this.token) {

            this.matDialogRef.close({
                status: 'notsent',
                message: 'Success',
                data: code
            });

            return;
        }

        this.loading = true;
        this.error = undefined;

        // Sign in
        // this._authService.signIn2Fa({
        //     code: this.code,
        //     token: this.token
        // }, this.captchaToken)
        //     .subscribe(
        //         (response) => {

        //             // Set the redirect url.
        //             // The '/signed-in-redirect' is a dummy url to catch the request and redirect the user
        //             // to the correct page after a successful sign in. This way, that url can be set via
        //             // routing file and we don't have to touch here.
        //             this.loading = false;

        //             this.matDialogRef.close({
        //                 status: 'ok',
        //                 message: 'Success',
        //                 data: response
        //             });
        //         },
        //         (response) => {

        //             this.loading = false;

        //             this.codeInput.reset();

        //             this.error = response?.error?.message || 'Something went wrong, please try again.';
        //         }
        //     );
    }
}
