import { Translation, TRANSLOCO_CONFIG, TRANSLOCO_LOADER, translocoConfig, TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { environment } from 'environments/environment';
import { TranslocoHttpLoader } from 'app/core/transloco/transloco.http-loader';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';

const availableLangs: any[] = [
    {
        id   : 'en-US',
        label: 'English',
        accepts: ['en', 'en-US']
    },
    {
        id   : 'es-ES',
        label: 'Spanish',
        accepts: ['es', 'es-419', 'es-ES']
    },
    {
        id   : 'pt-PT',
        label: 'Portuguese',
        accepts: ['pt', 'pt-BR', 'pt-PT']
    },
    // {
    //     id   : 'zh-CN',
    //     label: 'Chinese',
    //     accepts: ['zh', 'zh-cmn', 'zh-gan', 'zh-guoyu', 'zh-Hans', 'zh-xiang', 'zh-yue']
    // },
    // {
    //     id   : 'tr-TR',
    //     label: 'Turkish',
    //     accepts: ['tr', 'tr-TR', 'tr-CY']
    // },
    // {
    //     id   : 'it-IT',
    //     label: 'Italian',
    //     accepts: ['it', 'it-IT', 'it-CH', 'it-SM', 'it-VA']
    // },
    // {
    //     id   : 'fr-FR',
    //     label: 'French',
    //     accepts: ['fr', 'fr-FR', 'fr-CA', 'fr-BE', 'fr-GF']
    // },
    // {
    //     id   : 'ru-RU',
    //     label: 'Russian',
    //     accepts: ['ru', 'ru-RU', 'ru-BY', 'ru-KG', 'ru-KZ', 'ru-UA']
    // },
    // {
    //     id   : 'ja-JP',
    //     label: 'Japanese',
    //     accepts: ['ja', 'ja-JP']
    // },
    // {
    //     id   : 'de-DE',
    //     label: 'German',
    //     accepts: ['de', 'de-DE', 'de-AT', 'de-BE', 'de-CH']
    // },
    // {
    //     id   : 'hi-IN',
    //     label: 'Hindi',
    //     accepts: ['hi', 'hi-IN']
    // },
    // {
    //     id   : 'ar-SA',
    //     label: 'Arab',
    //     accepts: ['ar', 'ar-001', 'ar-SA', 'ar-AE', 'ar-EG', 'ar-IL', 'ar-QA']
    // }
];

@NgModule({
    imports: [
        TranslocoLocaleModule.forRoot()
    ],
    exports  : [
        TranslocoModule,
        TranslocoLocaleModule
    ],
    providers: [
        {
            // Provide the default Transloco configuration
            provide : TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs,
                defaultLang         : 'en-EN',
                fallbackLang        : 'en-EN',
                // failedRetries       : 2,
                reRenderOnLangChange: true,
                prodMode            : environment.production
            })
        },
        {
            // Provide the default Transloco loader
            provide : TRANSLOCO_LOADER,
            useClass: TranslocoHttpLoader
        },
        {
            // Preload the default language before the app starts to prevent empty/jumping content
            provide   : APP_INITIALIZER,
            deps      : [TranslocoService],
            useFactory: (translocoService: TranslocoService): any => (): Promise<Translation> => {
                const defaultLang = translocoService.getDefaultLang();
                const browserLang = navigator.language;
                let selectedLang = defaultLang;
                if(availableLangs.findIndex(item => item.id === localStorage.getItem('selectedLang')) !== -1) {
                    selectedLang = localStorage.getItem('selectedLang');
                } else if (availableLangs.findIndex(item => item.accepts.includes(browserLang)) !== -1) {
                    selectedLang = availableLangs.find(item => item.accepts.includes(browserLang)).id;
                }
                translocoService.setActiveLang(selectedLang);
                localStorage.setItem('selectedLang', selectedLang);
                return translocoService.load(selectedLang).toPromise();
            },
            multi     : true
        }
    ]
})
export class TranslocoCoreModule
{
}
