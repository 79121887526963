/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, Observable, ReplaySubject, tap } from 'rxjs';
import { User } from 'app/core/user/user.types';
import { FuseConfigService } from '@fuse/services/config';
import { AppConfig } from '../config/app.config';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
    providedIn: 'root'
})
export class UserService
{
    private config: AppConfig;
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _translocoService: TranslocoService,
        private _fuseConfigService: FuseConfigService
        )
    {
        // Subscribe to config changes
        this._fuseConfigService.config$
            .subscribe((config: AppConfig) => {

                // Store the config
                this.config = config;

            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User)
    {
        // Store the value
        this._user.next(value);
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    get user$(): Observable<User>
    {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<User>
    {
        const headers = new HttpHeaders({'Content-Type':'application/json', 'Accept':'application/json'});

        return this._httpClient.get<User>(`${this.config.apiPath}/admin/profile`, {headers: headers}).pipe(
            tap((user) => {

                const lang = user.lang === 'es' ? 'es-ES' : user.lang === 'pt' ? 'pt-PT' : 'en-US';

                localStorage.setItem('selectedLang', lang);

                // Set the active lang
                this._translocoService.setActiveLang(lang);

                this._user.next(user);
            })
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any>
    {
        return this._httpClient.patch<User>('api/common/user', {user}).pipe(
            map((response) => {
                this._user.next(response);
            })
        );
    }
}
