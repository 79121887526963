import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { take, takeUntil } from 'rxjs';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { ProfileService } from 'app/modules/admin/profile/profile.service';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
    selector       : 'languages',
    templateUrl    : './languages.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'languages'
})
export class LanguagesComponent implements OnInit, OnDestroy
{
    availableLangs: AvailableLangs;
    activeLang: string;
    flagCodes: any;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _translocoService: TranslocoService,
        private _profileService: ProfileService,
        private _authService: AuthService,
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Get the available languages from transloco
        this.availableLangs = this._translocoService.getAvailableLangs();

        // Subscribe to language changes
        this._translocoService.langChanges$.subscribe((activeLang) => {

            // Get the active lang
            this.activeLang = activeLang;

            // Update the navigation
            setTimeout(() => {
                this._updateNavigation(activeLang);
            }, 200);
        });

        // Set the country iso codes for languages for flags
        this.flagCodes = {
            'en-US': 'us',
            'es-ES': 'es',
            'pt-PT': 'pt',
            'zh-CN': 'cn',
            'tr-TR': 'tr',
            'it-IT': 'it',
            'fr-FR': 'fr',
            'ru-RU': 'rs',
            'ja-JP': 'jp',
            'de-DE': 'de',
            'hi-IN': 'in',
            'ar-SA': 'sa',
        };
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the active lang
     *
     * @param lang
     */
    setActiveLang(lang: string): void
    {
        // Set the active lang
        this._translocoService.setActiveLang(lang);

        localStorage.setItem('selectedLang', lang);

        this._authService
            .check()
            .pipe(take(1))
            .subscribe((auth: boolean) => {
                if (auth) {
                    const langu =
                        lang === 'es-ES'
                            ? 'es'
                            : lang === 'pt-PT'
                            ? 'pt'
                            : 'en';

                    this._profileService
                        .changeLanguage({ lang: langu })
                        .pipe(take(1))
                        .subscribe(() => {
                            this._profileService
                                .getProfile()
                                .pipe(take(1))
                                .subscribe();
                        });
                }
            });
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the navigation
     *
     * @param lang
     * @private
     */
    private _updateNavigation(lang: string): void
    {
        // For the demonstration purposes, we will only update the Dashboard names
        // from the navigation but you can do a full swap and change the entire
        // navigation data.
        //
        // You can import the data from a file or request it from your backend,
        // it's up to you.

        // Get the component -> navigation data -> item
        const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');

        // Return if the navigation component does not exist
        if ( !navComponent )
        {
            return null;
        }

        // Get the flat navigation data
        const navigation = navComponent.navigation;

        // Get the Dashboard item and update its title
        const dashboardItem = this._fuseNavigationService.getItem('dashboard', navigation);
        if ( dashboardItem )
        {
            this._translocoService.selectTranslate('Dashboard').pipe(take(1))
                .subscribe((translation) => {

                    // Set the title
                    dashboardItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Clients item and update its title
        const clientsItem = this._fuseNavigationService.getItem('clients', navigation);
        if ( clientsItem )
        {
            this._translocoService.selectTranslate('Clients').pipe(take(1))
                .subscribe((translation) => {

                    // Set the title
                    clientsItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Contests item and update its title
        const contestsItem = this._fuseNavigationService.getItem('contests', navigation);
        if ( contestsItem )
        {
            this._translocoService.selectTranslate('Contests').pipe(take(1))
                .subscribe((translation) => {

                    // Set the title
                    contestsItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        const contestsraffleItem = this._fuseNavigationService.getItem('contests.raffle', navigation);
        if ( contestsraffleItem )
        {
            this._translocoService.selectTranslate('Raffle').pipe(take(1))
                .subscribe((translation) => {

                    // Set the title
                    contestsraffleItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        const contestslotteryItem = this._fuseNavigationService.getItem('contests.lottery', navigation);
        if ( contestslotteryItem )
        {
            this._translocoService.selectTranslate('Lottery').pipe(take(1))
                .subscribe((translation) => {

                    // Set the title
                    contestslotteryItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Finances item and update its title
        const financesItem = this._fuseNavigationService.getItem('finances', navigation);
        if ( financesItem )
        {
            this._translocoService.selectTranslate('Finances').pipe(take(1))
                .subscribe((translation) => {

                    // Set the title
                    financesItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        const financesdepositsItem = this._fuseNavigationService.getItem('finances.deposits', navigation);
        if ( financesdepositsItem )
        {
            this._translocoService.selectTranslate('Deposits').pipe(take(1))
                .subscribe((translation) => {

                    // Set the title
                    financesdepositsItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        const financeswithdrawalsItem = this._fuseNavigationService.getItem('finances.withdrawals', navigation);
        if ( financeswithdrawalsItem )
        {
            this._translocoService.selectTranslate('Withdrawals').pipe(take(1))
                .subscribe((translation) => {

                    // Set the title
                    financeswithdrawalsItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        const financespurchasesItem = this._fuseNavigationService.getItem('finances.purchases', navigation);
        if ( financespurchasesItem )
        {
            this._translocoService.selectTranslate('Purchases').pipe(take(1))
                .subscribe((translation) => {

                    // Set the title
                    financespurchasesItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        const financesrewardsItem = this._fuseNavigationService.getItem('finances.rewards', navigation);
        if ( financesrewardsItem )
        {
            this._translocoService.selectTranslate('Rewards').pipe(take(1))
                .subscribe((translation) => {

                    // Set the title
                    financesrewardsItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        const financesrefundsItem = this._fuseNavigationService.getItem('finances.refunds', navigation);
        if ( financesrefundsItem )
        {
            this._translocoService.selectTranslate('Refunds').pipe(take(1))
                .subscribe((translation) => {

                    // Set the title
                    financesrefundsItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Users item and update its title
        const usersItem = this._fuseNavigationService.getItem('users', navigation);
        if ( usersItem )
        {
            this._translocoService.selectTranslate('Users').pipe(take(1))
                .subscribe((translation) => {

                    // Set the title
                    usersItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Settings item and update its title
        const settingsItem = this._fuseNavigationService.getItem('settings', navigation);
        if ( settingsItem )
        {
            this._translocoService.selectTranslate('Settings').pipe(take(1))
                .subscribe((translation) => {

                    // Set the title
                    settingsItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Settings item and update its title
        const settingscategoriesItem = this._fuseNavigationService.getItem('settings.categories', navigation);
        if ( settingscategoriesItem )
        {
            this._translocoService.selectTranslate('Categories').pipe(take(1))
                .subscribe((translation) => {

                    // Set the title
                    settingscategoriesItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }
    }
}
