import { Component, Inject, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import parsePhoneNumber from 'libphonenumber-js';
import { CodeInputComponent } from 'angular-code-input';
import { getAuth, signInWithPhoneNumber } from 'firebase/auth';

@Component({
    selector: 'verify-phone',
    templateUrl: './verify-phone.component.html',
    styleUrls: ['./verify-phone.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VerifyPhoneComponent implements OnInit, OnDestroy {

    @ViewChild('codeInput') codeInput !: CodeInputComponent;

    loading: boolean = false;

    phoneNumber: any;

    error: string;

    code: string = '';

    auth: any;

    recaptchaVerifier: any;

    confirmationResult: any;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { confirmationResult: any; phone: string; recaptchaVerifier: any },
        public matDialogRef: MatDialogRef<VerifyPhoneComponent>
    ) {
        this.phoneNumber = parsePhoneNumber(data.phone).formatInternational();
        this.auth = getAuth();
        this.recaptchaVerifier = data.recaptchaVerifier;
        this.confirmationResult = data.confirmationResult;
    }

    /**
     * On init
     */
     ngOnInit(): void {
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    // this called every time when user changed the code
    onCodeChanged(code: string): void {
        this.code = code;
    }

    // this called only if user entered full code
    onCodeCompleted(code: string): void {
        this.sendCode(code);
    }

    /**
     * Send code to firebase
     *
     * @param code
     */
    sendCode(code: string): void {

        if(code.length < 6) {
            return;
        }

        this.loading = true;
        this.error = undefined;

        try {
            this.confirmationResult.confirm(code)
                .then((good) => {
                    this.loading = false;

                    this.matDialogRef.close({
                        status: 'ok',
                        message: 'Success',
                        data: good
                    });
                })
                .catch((bad) => {
                    // code verification was bad.
                    this.loading = false;

                    this.codeInput.reset();

                    this.error = `error.${bad?.message}`;
                });
        } catch (e) {
            this.loading = false;
        }
    }

    /**
     * Resend code
     */
    reSendCode(): void {

        this.loading = true;
        this.error = undefined;

        signInWithPhoneNumber(
            this.auth,
            this.phoneNumber,
            this.recaptchaVerifier
        )
        .then((confirmationResult) => {

            this.confirmationResult = confirmationResult;
            this.loading = false;
            this.codeInput.reset();

        })
        .catch((error) => {
            // Error; SMS not sent
            this.loading = false;
            console.error(error);
            this.error = error?.message;
            this.recaptchaVerifier.render().then((widgetId: any) => {
                this.recaptchaVerifier.reset(widgetId);
            });
        });
    }
}
