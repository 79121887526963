import { NgModule } from '@angular/core';
import { LayoutComponent } from 'app/layout/layout.component';
import { EmptyLayoutModule } from 'app/layout/layouts/empty/empty.module';
import { CenteredLayoutModule } from 'app/layout/layouts/horizontal/centered/centered.module';
import { EnterpriseLayoutModule } from 'app/layout/layouts/horizontal/enterprise/enterprise.module';
import { MaterialLayoutModule } from 'app/layout/layouts/horizontal/material/material.module';
import { ModernLayoutModule } from 'app/layout/layouts/horizontal/modern/modern.module';
import { ClassicLayoutModule } from 'app/layout/layouts/vertical/classic/classic.module';
import { ClassyLayoutModule } from 'app/layout/layouts/vertical/classy/classy.module';
import { CompactLayoutModule } from 'app/layout/layouts/vertical/compact/compact.module';
import { DenseLayoutModule } from 'app/layout/layouts/vertical/dense/dense.module';
import { FuturisticLayoutModule } from 'app/layout/layouts/vertical/futuristic/futuristic.module';
import { ThinLayoutModule } from 'app/layout/layouts/vertical/thin/thin.module';
import { SettingsModule } from 'app/layout/common/settings/settings.module';
import { SharedModule } from 'app/shared/shared.module';
import { VerifyPhoneComponent } from 'app/modules/auth/verify-phone/verify-phone.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CodeInputModule } from 'angular-code-input';
import { FuseAlertModule } from '@fuse/components/alert';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FuseConfirmationModule } from '@fuse/services/confirmation';
import { TranslocoModule } from '@ngneat/transloco';
import { LanguagesModule } from './common/languages/languages.module';
import { FuseScrollbarModule } from '@fuse/directives/scrollbar';
import { PrivacyPolicyComponent } from 'app/modules/auth/privacy-policy/privacy-policy.component';
import { SecondFactorCodeComponent } from 'app/modules/auth/second-factor-code/second-factor-code.component';

const layoutModules = [
    // Empty
    EmptyLayoutModule,

    // Horizontal navigation
    CenteredLayoutModule,
    EnterpriseLayoutModule,
    MaterialLayoutModule,
    ModernLayoutModule,

    // Vertical navigation
    ClassicLayoutModule,
    ClassyLayoutModule,
    CompactLayoutModule,
    DenseLayoutModule,
    FuturisticLayoutModule,
    ThinLayoutModule,

    // Material dependences
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    CodeInputModule,
    FuseAlertModule,
    MatProgressSpinnerModule,
    FuseConfirmationModule,
    FuseScrollbarModule,

    // Translate dependences
    TranslocoModule,
    LanguagesModule
];

@NgModule({
    declarations: [
        LayoutComponent,
        VerifyPhoneComponent,
        PrivacyPolicyComponent,
        SecondFactorCodeComponent
    ],
    imports     : [
        SharedModule,
        SettingsModule,
        ...layoutModules
    ],
    exports     : [
        LayoutComponent,
        ...layoutModules
    ],
    providers   : [
        VerifyPhoneComponent,
        PrivacyPolicyComponent,
        SecondFactorCodeComponent
    ]
})
export class LayoutModule
{
}
