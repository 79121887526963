<div class="relative flex flex-col w-full h-full" *transloco="let t">

    <!-- Content -->
    <div class="flex flex-col sm:flex-row flex-auto items-center sm:items-start pt-8 px-4 sm:px-8 pb-6 sm:pb-8">

        <!-- Info section -->
        <div class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 space-y-1 text-center sm:text-left">

            <!-- Title -->
            <div class="text-xl leading-6 font-medium w-full text-center mb-4">Privacy Policy</div>

            <!-- Message -->
            <div class="text-md leading-6 max-h-128 px-4 md:px-8" fuseScrollbar>

                <p class="text-justify mb-2">
                    This policy refers only to services provided by AX Payments. The products and services you purchase
                    from our customers are subject to their own terms of use and privacy policies, determined by them.
                </p>

                <p class="text-justify mb-2">
                    1. Acceptance: The User must read, carefully and in its entirety, the content of these Terms and, if
                    he agrees with its provisions, he must express his free, express, informed and unequivocal consent
                    in relation to its content. This acceptance will be made by clicking on the button and "I read and
                    accept the Terms of Use and the Privacy Policy", at the User's first access.
                </p>

                <p class="text-justify">
                    2. Personal information: Some data that we use to provide our Services may be considered personal
                    data, that is, information related to you that, individually or together, can identify you. We may
                    use the following data so that you can take advantage of our Services, as well as to improve them:
                </p>
                <ul class="list-disc ml-4">
                    <li class="text-left">Identity data: includes information about your identity, such as your full
                        name, tax identification number, date of birth, address, email and phone number.</li>
                    <li class="text-left">Financial data: includes information about payment details, bank and financial
                        information.</li>
                    <li class="text-left">Technical data: includes information about your IP, time and date of access,
                        geolocation, data about your access device and cookies.</li>
                    <li class="text-left">Usage data: includes information about how you use our Service, such as
                        profile and purchasing behavior and volume of transactions.</li>
                    <li class="text-left">Biometric data: includes information such as a photo of your documents and
                        personal photos.</li>
                </ul>

                <p class="text-justify my-2">
                    2.1. We may also collect aggregate data, such as statistical or demographic data. Aggregates can be
                    derived from your personal data, but they are not considered personal data in the law, as they do
                    not reveal your identity directly or indirectly.
                </p>

                <p class="text-justify mb-2">
                    2.2. Personal data can be provided freely by the user or collected automatically when using this
                    service. Any use of cookies or other monitoring tools, by this website, application or by the owners
                    of aggregated third party services, serves to identify users and remember their preferences, with
                    the sole purpose of providing the service required by the user. If you do not provide certain
                    Personal Data, it may be impossible for this website or application to provide your services.
                </p>

                <p class="text-justify mb-2">
                    2.3. You expressly authorize to AX Payments share part of your information with third parties and
                    with companies in the same economic group to continue enjoying the best services and experience. We
                    may share data with national or international third parties, such as identification and
                    authentication; hosting providers; preventing and combating financial crimes; tax identification
                    agencies; technical or security problems in the identification processes; among others. Certain
                    information may also be shared to comply with legal obligations.
                </p>

                <p class="text-justify mb-2">
                    2.4. Your personal data may also be shared with state and regulatory agencies to comply with the
                    legal and regulatory obligations to which we send in all territories where we provide our Services.
                </p>

                <p class="text-justify mb-2">
                    2.5. In addition, we may share your personal data to protect our rights, that of customers or third
                    parties, to protect your legitimate interests or that of another person, to apply our terms of use
                    or other contracts, and to present or exercise defense in legal claims.
                </p>

                <p class="text-justify mb-2">
                    2.6. We do not license, sell or transfer your personal data to anyone, under any circumstances, for
                    the purpose of making a profit or contrary to what this Policy contains.
                </p>

                <p class="text-justify mb-2">
                    2.7. We keep your personal data only for the time necessary to fulfill the purposes for which we
                    collect it, including to comply with any legal, contractual, liability or request from the competent
                    authorities, subject to a minimum period of 5 (five) years from the date of data collection.
                </p>

                <p class="text-justify mb-2">
                    2.8. If you request the deletion of your personal data, it will be deleted as soon as the
                    aforementioned legal maintenance period ends, unless its maintenance is determined on the basis of
                    legal obligations or at the request of a competent authority.
                </p>

                <p class="text-justify mb-2">
                    2.9. AX Payments reserves the right to make changes to this privacy policy at any time by notifying
                    its users on this page. It is highly recommended to consult this page frequently, referring to the
                    date of the last modification that appears at the bottom. If a user objects to any changes to the
                    policy, the user must stop using this service.
                </p>
            </div>

        </div>

    </div>

    <div
        class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">

        <!-- Cancel -->
        <button mat-stroked-button [matDialogClose]="undefined" [disabled]="loading">
            {{t('Close')}}
        </button>

    </div>

</div>
