import { environment } from 'environments/environment';
import { Layout } from 'app/layout/layout.types';

// Types
export type Scheme = 'auto' | 'dark' | 'light';
export type Theme = 'default' | string;

/**
 * AppConfig interface. Update this interface to strictly type your config
 * object.
 */
export interface AppConfig
{
    layout: Layout;
    scheme: Scheme;
    theme: Theme;
    apiPath: string;
}

/**
 * Default configuration for the entire application. This object is used by
 * FuseConfigService to set the default configuration.
 *
 * If you need to store global configuration for your app, you can use this
 * object to set the defaults. To access, update and reset the config, use
 * FuseConfigService and its methods.
 */
export const appConfig: AppConfig = {
    layout: localStorage.getItem('layout') ? localStorage.getItem('layout') as Layout : 'thin',
    scheme: localStorage.getItem('scheme') ? localStorage.getItem('scheme') as Scheme : 'dark',
    theme : localStorage.getItem('theme') ? localStorage.getItem('theme') as Theme : 'indigo',
    apiPath : environment.apiPath
};
