/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { FuseConfigService } from '@fuse/services/config';
import { AppConfig } from 'app/core/config/app.config';
import { AdminUser } from 'app/models';

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    // Private
    private config: AppConfig;
    private _user: BehaviorSubject<AdminUser | null> = new BehaviorSubject(
        null
    );

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _fuseConfigService: FuseConfigService
    ) {
        // Subscribe to config changes
        this._fuseConfigService.config$.subscribe((config: AppConfig) => {
            // Store the config
            this.config = config;
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for user
     */
    get _user$(): Observable<AdminUser> {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get profile
     */
    getProfile(): Observable<AdminUser> {
        return this._httpClient
            .get<AdminUser>(`${this.config.apiPath}/admin/profile`)
            .pipe(
                tap((response) => {
                    this._user.next(response);
                })
            );
    }

    /**
     * Change password
     */
    changePassword(params: {
        password: string;
        password_confirmation: string;
    }): Observable<any> {
        return this._httpClient.post<any>(
            `${this.config.apiPath}/admin/change-password`,
            params
        );
    }

    /**
     * Change language
     */
    changeLanguage(params: { lang: 'es' | 'en' | 'pt' }): Observable<any> {
        return this._httpClient.post<any>(
            `${this.config.apiPath}/admin/language`,
            params
        );
    }
}
